import { isEmpty, pick } from 'lodash-es';

import runtimeConfig from '~config/config';

import { RECOMMENDATION_TRAY_PAGE_SIZE } from '~modules/catalog/js/catalog-constants';

const { apiUrls } = runtimeConfig;

// NU_FEATURE URBNSearch V2 Trays
// https://urbnit.atlassian.net/browse/TYP-28163
// remove this function once the feature is fully implemented
// eslint-disable-next-line import/prefer-default-export
export function getRecommendationItems(r15Svc, catalogSlug, query = {}, catalogFilterWhitelist = [], logger) {
    if (isEmpty(catalogFilterWhitelist)) {
        logger?.error('No catalogFilterWhitelist passed to GET getRecommendationItems');
    }

    const whitelistedQuery = pick(query, catalogFilterWhitelist);

    const options = {
        params: {
            ...whitelistedQuery,
            pageNumber: whitelistedQuery.pageNumber || 1,
            itemsPerPage: whitelistedQuery.itemsPerPage || RECOMMENDATION_TRAY_PAGE_SIZE,
        },
    };

    return r15Svc.get(`${apiUrls.catalogService}/recommendation/${catalogSlug}`, options);
}

export function getCatalogItems({
    r15Svc,
    catalogSlug,
    query = {},
    catalogFilterWhitelist = [],
    logger,
}) {
    if (isEmpty(catalogFilterWhitelist)) {
        logger.error('No catalogFilterWhitelist passed to GET getCatalogItems');
    }
    const whitelistedQuery = pick(query, catalogFilterWhitelist);
    const options = {
        params: {
            ...whitelistedQuery,
            pageNumber: whitelistedQuery.pageNumber || 1,
            itemsPerPage: whitelistedQuery.itemsPerPage || RECOMMENDATION_TRAY_PAGE_SIZE,
        },
    };

    return r15Svc.get(`${apiUrls.catalogServiceV2}/${catalogSlug}`, options);
}
