<template>
    <CoreProductTray
        :id="id"
        :listId="listId"
        :listName="listName"
        :contentModuleId="contentModuleId"
        :contentTitle="contentTitle"
        :imagePreset="imagePreset"
        :catalogTile="catalogTile"
        :products="products"
        :headerLink="headerLink"
        :headerTitle="headerTitle"
        :headerSubtitle="headerSubtitle"
        :totalProducts="totalProducts"
        :mobilePeek="mobilePeek"
        :mobilePeekPercent="mobilePeekPercent"
        :mediumPeekPercent="mediumPeekPercent"
        :displayCounter="displayCounter"
        :desktopSlideSize="desktopSlideSize"
        :mediumSlideSize="mediumSlideSize"
        :mobileSlideSize="mobileSlideSize"
        :translucentButtons="translucentButtons"
        :lightButtons="lightButtons"
        :lightInfoContainer="lightInfoContainer"
        :catalogResponseId="catalogResponseId"
        :showProductInfo="showProductInfo"
        :showEmptyContentSlot="showEmptyContentSlot"
        :showClosetButtonOnTile="showClosetButtonOnTile"
        :showReviewButtonOnTile="showReviewButtonOnTile"
        :showAddToList="showAddToList"
        :showShopTheLookBanner="showShopTheLookBanner"
        :fillEmptyPageWithBlankTiles="fillEmptyPageWithBlankTiles"
        :carouselItemUniqueKey="carouselItemUniqueKey"
        :analyticsType="analyticsType"
    >
        <template v-if="showEmptyContentSlot" #empty-content>
            <slot name="empty-content"></slot>
        </template>
    </CoreProductTray>
</template>

<script>
import { markRaw, defineAsyncComponent } from 'vue';
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { GLOBAL_IMAGE_PRESETS } from '~coreModules/core/js/constants';
import { getCatalogTrayAnalyticsData } from '~modules/catalog/js/catalog-utils';

import ProductTrayProps from '~coreModules/core/components/mixins/productTrayProps';
import CoreProductTray from '~coreModules/core/components/ui/trays/CoreProductTray.vue';

export default {
    name: 'ProductTray',
    components: {
        CoreProductTray,
    },
    mixins: [
        ProductTrayProps,
    ],
    props: {
        products: {
            type: Array,
            required: true,
        },
        totalProducts: {
            type: Number,
            default: 15,
        },
        showEmptyContentSlot: {
            type: Boolean,
            default: false,
        },
        showClosetButtonOnTile: {
            type: Boolean,
            default: true,
        },
        showReviewButtonOnTile: {
            type: Boolean,
            default: false,
        },
        fillEmptyPageWithBlankTiles: {
            type: Boolean,
            default: false,
        },
        lightInfoContainer: {
            type: Boolean,
            default: true,
        },
        catalogResponseId: {
            type: String,
            default: null,
        },
        carouselItemUniqueKey: {
            type: String,
            default: null,
        },
        analyticsType: {
            type: String,
            default: null,
        },
        traceId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            imagePreset: GLOBAL_IMAGE_PRESETS.productTray,
            catalogTile: markRaw(
                defineAsyncComponent(
                    () => import(
                        /* webpackChunkName: "catalog-tile" */
                        '~modules/catalog/components/CatalogTile.vue'),
                ),
            ),
        };
    },
    created() {
        if (process.env.VUE_ENV === 'client') {
            this.trackViewProductList();
        }
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        trackViewProductList() {
            if (!this.listName) return;

            this.trackGlobalEvent(getCatalogTrayAnalyticsData({
                products: this.products,
                catalogResponseId: this.catalogResponseId,
                filters: this.filters,
                traceId: this.traceId,
                listName: this.listName,
                listId: this.listId,
            }));
        },
    },
};
</script>
