<i18n>
[
    "global__viewAll",
]
</i18n>

<template>
    <div class="c-product-tray-module">
        <ProductTrayHeader
            :id="id"
            :link="headerLink"
            :title="headerTitle"
            :subtitle="headerSubtitle"
            :totalProducts="totalProducts"
            :displayCounter="displayCounter"
            :numberOfItemsInView="smallestNumberOfItemsInView"
            :contentModuleId="contentModuleId"
            :contentTitle="contentTitle"
        />
        <slot v-if="showEmptyContentSlot" name="empty-content"></slot>
        <template v-else>
            <Carousel
                :class="{
                    'c-product-tray-module__carousel': true,
                    'is-flex': numberOfBlankTiles,
                }"
                :name="id"
                :carouselItems="productsWithBlankTiles"
                :desktopSlideSize="desktopSlideSize"
                :mobileSlideSize="mobileSlideSize"
                :mobilePeek="mobilePeek"
                :mobilePeekPercent="mobilePeekPercent"
                :mediumPeekPercent="mediumPeekPercent"
                :translucentButtons="translucentButtons"
                :lightButtons="lightButtons"
                :catalogResponseId="catalogResponseId"
                :resetOnResize="true"
                :carouselItemUniqueKey="carouselItemUniqueKey"
                :analyticsType="analyticsType"
                @carousel-moved="carouselMoved"
            >
                <template
                    v-for="(product, index) in products"
                    :key="getCarouselSlotName(product, carouselItemUniqueKey, index)"
                    #[getCarouselSlotName(product,carouselItemUniqueKey,index)]
                >
                    <LazyLoad v-slot="{ hasEnteredViewport }">
                        <component
                            :is="catalogTile"
                            :hasEnteredViewport="hasEnteredViewport"
                            :product="product"
                            :imagePreset="imagePreset"
                            :hideInfo="!showProductInfo"
                            :showClosetButton="showClosetButtonOnTile"
                            :showReviewButton="showReviewButtonOnTile"
                            :smallClosetButton="true"
                            :productTrayId="id"
                            :productListId="listId"
                            :productListName="listName"
                            :useBottomMargin="false"
                            :catalogResponseId="catalogResponseId"
                            :showLikeButton="showLikeButton"
                            :showAddToList="showAddToList"
                            :showShopTheLookBanner="product?.showShopTheLookBanner"
                            :lightInfoContainer="lightInfoContainer"
                            :index="index"
                            :closetListId="closetListId"
                            class="c-product-tray-module__tile"
                            @toggle-save-product-clicked="toggleSaveProductClicked"
                        />
                    </LazyLoad>
                </template>
                <template
                    v-for="(_, index) in numberOfBlankTiles"
                    :key="`blank-tile-${index}`"
                    #[getCarouselSlotName({},null,index+products.length)]
                >
                    <EmptyCatalogTile />
                </template>
            </Carousel>
        </template>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';

import { getCarouselSlotName } from '~coreModules/core/js/carousel-utils';

import Carousel from '~coreModules/core/components/ui/Carousel.vue';
import ProductTrayHeader from '~coreModules/core/components/ui/trays/ProductTrayHeader.vue';
import EmptyCatalogTile from '~coreModules/catalog/components/EmptyCatalogTile.vue';
import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';
import ProductTrayProps from '~coreModules/core/components/mixins/productTrayProps.js';

export default {
    name: 'CoreProductTray',
    components: {
        Carousel,
        EmptyCatalogTile,
        ProductTrayHeader,
        LazyLoad,
    },
    mixins: [
        ProductTrayProps,
    ],
    props: {
        // Function returning a dynamically imported component, or a static component object
        catalogTile: {
            type: [Function, Object],
            required: true,
        },
        imagePreset: {
            type: [String, Object],
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        carouselItemUniqueKey: {
            type: String,
            default: null,
        },
        totalProducts: {
            type: Number,
            default: 15,
        },
        showEmptyContentSlot: {
            type: Boolean,
            default: false,
        },
        showClosetButtonOnTile: {
            type: Boolean,
            default: true,
        },
        showReviewButtonOnTile: {
            type: Boolean,
            default: false,
        },
        fillEmptyPageWithBlankTiles: {
            type: Boolean,
            default: false,
        },
        lightButtons: {
            type: Boolean,
            default: false,
        },
        lightInfoContainer: {
            type: Boolean,
            default: true,
        },
        catalogResponseId: {
            type: String,
            default: null,
        },
        analyticsType: {
            type: String,
            default: null,
        },
        closetListId: {
            type: String,
            default: null,
        },
    },
    emits: ['toggle-save-product-clicked'],
    data() {
        return {
            numberOfItemsInView: null,
            getCarouselSlotName,
        };
    },
    computed: {
        smallestNumberOfItemsInView() {
            return Math.min(this.totalProducts, this.numberOfProductTilesInView);
        },
        pageSize() {
            if (this.$mediaQueries.isSmallish) {
                return this.mobileSlideSize;
            }

            if (this.$mediaQueries.isMedium) {
                return this.mediumSlideSize;
            }

            return this.desktopSlideSize;
        },
        numberOfBlankTiles() {
            if (!this.fillEmptyPageWithBlankTiles) {
                return 0;
            }

            const blankTileCount = this.pageSize - this.products.length;

            return blankTileCount > 0 ? blankTileCount : 0;
        },
        productsWithBlankTiles() {
            const productsWithBlankTiles = [...this.products];

            if (this.numberOfBlankTiles) {
                for (let i = 0; i < this.numberOfBlankTiles; i++) {
                    productsWithBlankTiles.push({});
                }
            }

            return productsWithBlankTiles;
        },
        numberOfProductTilesInView() {
            if (!this.numberOfItemsInView && this.numberOfBlankTiles) {
                return this.products.length;
            }
            return this.numberOfItemsInView - this.numberOfBlankTiles;
        },
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        carouselMoved(carouselInfo) {
            const { numberOfItemsInView } = carouselInfo;

            this.numberOfItemsInView = numberOfItemsInView;
        },
        toggleSaveProductClicked(args) {
            this.$emit('toggle-save-product-clicked', args);
        },
    },
};
</script>

<style lang="scss">
    .c-product-tray-module {
        $this: &;
        position: relative;
        margin: 0 auto $nu-spacer-1;

        &__header-container {
            align-items: center;
            margin-bottom: $nu-spacer-2;
            padding: 0 $nu-spacer-2;

            @include breakpoint(large) {
                padding: 0 $nu-spacer-12;
                margin: 0 $nu-spacer-1 $nu-spacer-4;
            }
        }

        &__header {
            text-align: center;
            vertical-align: middle;
            padding-bottom: 0;

            &--mobile {
                padding: 0 $nu-spacer-2;

                @include breakpoint(large) {
                    display: none;
                }
            }

            &--desktop {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }
        }

        &__browse-link {
            padding-right: $nu-spacer-1;

            @include breakpoint(large) {
                grid-column-start: inherit;
                padding-right: 0;
            }
        }

        &__tile {
            position: relative;
            display: inline-block;
            width: 100%;
            vertical-align: top;
            text-align: center;
            background-color: $transparent;
            margin: 0;
        }

        &__counter {
            padding-left: $nu-spacer-1;
            white-space: nowrap;

            @include breakpoint(large) {
                padding-left: 0;
            }
        }

        &__carousel.is-flex .c-carousel__slide-container {
            display: flex;
        }

        &__blank-tile {
            height: 100%;
            background-color: $placeholder-background;
        }
    }
</style>
