import { CATALOG_QUERY_SORT } from '~coreModules/catalog/js/catalog-constants';
import { VIEW_PRODUCT_LIST } from '~coreModules/core/js/global-event-constants';
import { URBN_BRANDS } from '~coreModules/core/js/constants';
import { getFilterStringFromObject } from '~coreModules/catalog/js/catalog-utils';

/**
 * Returns the product brand display name
 *
 * @param  {Array} vendorBrands - the brands associated with the product
 * @return {String} the brand name
 */
export function getCatalogProductBrand(vendorBrands) {
    if (!vendorBrands?.length) return '';
    if (vendorBrands.length > 1) {
        return vendorBrands.filter(brand => !URBN_BRANDS.includes(brand))[0];
    }

    return vendorBrands[0];
}

/**
 * Returns the product brand display name
 *
 * @param  {Object} options - Options object
 * @param  {Array} options.products - products in the list
 * @param  {String} options.catalogResponseId - catalog response id for the list response
 * @param  {Array} options.filters - filters applied to the catalog request for the list
 * @param  {String} options.traceId - trace id sent from Data Science
 * @param  {String} options.listName - name of list
 * @param  {String} options.listId - id of the list
 * @return {Object} Analytics Object to be sent to GA4
 */
export function getCatalogTrayAnalyticsData({ products, catalogResponseId, filters, traceId, listName, listId }) {
    const shapedProducts = (products || []).map(product => ({
        productListName: listName,
        productListId: listId,
        productName: product.displayName,
        productPrice: product.msrpValue,
        productBrand: getCatalogProductBrand(product.vendorBrands),
        productId: product.choiceId,
    }));

    return {
        type: VIEW_PRODUCT_LIST,
        data: {
            products: shapedProducts,
            productList: {
                sortMethod: filters[CATALOG_QUERY_SORT] || '',
                responseId: catalogResponseId,
                filterString: getFilterStringFromObject(filters),
            },
            traceId,
        },
    };
}
