import {
    PRODUCT_TRAY_DEFAULT_PEEK_MOBILE,
    PRODUCT_TRAY_DEFAULT_PEEK_MEDIUM,
} from '~coreModules/core/js/carousel-constants';

export default {
    props: {
        id: {
            type: String,
            default: null,
        },
        listId: {
            type: String,
            default: null,
        },
        listName: {
            type: String,
            default: null,
        },
        contentModuleId: {
            type: String,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
        headerLink: {
            type: Object,
            default: null,
        },
        headerTitle: {
            type: String,
            default: null,
        },
        headerSubtitle: {
            type: String,
            default: null,
        },
        desktopSlideSize: {
            type: Number,
            default: 5,
        },
        mediumSlideSize: {
            type: Number,
            default: 3,
        },
        mobileSlideSize: {
            type: Number,
            default: 2,
        },
        mobilePeek: {
            type: Boolean,
            default: true,
        },
        mobilePeekPercent: {
            type: Number,
            default: PRODUCT_TRAY_DEFAULT_PEEK_MOBILE,
        },
        mediumPeekPercent: {
            type: Number,
            default: PRODUCT_TRAY_DEFAULT_PEEK_MEDIUM,
        },
        displayCounter: {
            type: Boolean,
            default: true,
        },
        translucentButtons: {
            type: Boolean,
            default: false,
        },
        lightButtons: {
            type: Boolean,
            default: false,
        },
        showLikeButton: {
            type: Boolean,
            default: true,
        },
        showAddToList: {
            type: Boolean,
            default: false,
        },
        showShopTheLookBanner: {
            type: Boolean,
            default: false,
        },
        showProductInfo: {
            type: Boolean,
            default: false,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
    },
};
