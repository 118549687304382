<template>
    <div class="c-empty-catalog-tile">
        <div class="c-empty-catalog-tile__image-container">
            <div class="c-empty-catalog-tile__image-container-inner o-flex-horizontal-center o-flex-vertical-center">
                <Illustration
                    v-if="illustration"
                    :illustrationSrc="illustration"
                    illustrationSize="96"
                />
            </div>
        </div>
    </div>
</template>

<script>

import Illustration from '~coreModules/core/components/ui/Illustration.vue';

export default {
    name: 'EmptyCatalogTile',
    components: {
        Illustration,
    },
    props: {
        illustration: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang='scss'>
    .c-empty-catalog-tile {
        width: 100%;
        height: 100%;
        background-color: $placeholder-background;
        position: relative;
        overflow: hidden;

        &__image-container {
            &:before {
                position: relative;
                display: block;
                content: "";
                width: 100%;
                padding-top: 150%;
                background-color: $placeholder-background;
            }
        }

        &__image-container-inner {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &__image {
            width: 100%
        }
    }
</style>
