<i18n>
[
    "global__viewAll",
]
</i18n>

<template>
    <div class="c-product-tray-header">
        <div class="c-product-tray-header__header--mobile u-text--center u-spacer--1pt5">
            <h3 v-if="title" class="u-spacer--1">
                {{ title }}
            </h3>
            <p v-if="subtitle" class="o-text--caption u-text--gray">
                {{ subtitle }}
            </p>
        </div>
        <div
            v-if="displayCounter || title || link"
            class="o-row-full c-product-tray-header__header-container"
        >
            <div class="o-extra-small--2">
                <p
                    v-if="displayCounter"
                    class="c-product-tray-header__counter u-text--left o-text--caption u-text--gray"
                >
                    {{ slideCounter }}
                </p>
            </div>
            <div class="c-product-tray-header__header--desktop o-extra-small--8 u-text--center">
                <h3 v-if="title" class="u-spacer--1">
                    {{ title }}
                </h3>
                <p v-if="subtitle" class="o-text--caption u-text--gray">
                    {{ subtitle }}
                </p>
            </div>
            <div
                v-if="link"
                :class="[
                    'o-extra-small--6',
                    'o-large--2',
                    'c-product-tray-header__browse-link',
                    'u-text--right',
                    'o-extra-small--offset-6',
                ]"
            >
                <BaseLink
                    :to="link.linkPath"
                    class="o-text--link o-text--caption"
                    @click="handleViewAllClick"
                >
                    {{ link.linkTitle }}
                </BaseLink>
            </div>
        </div>
    </div>
</template>

<script>
import { isObject } from 'lodash-es';
import { mapActions } from 'vuex';

import { VIEW_ALL_CLICK } from '~coreModules/core/js/global-event-constants';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';

export default {
    name: 'ProductTrayHeader',
    props: {
        id: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        link: {
            type: Object,
            default: null,
        },
        totalProducts: {
            type: Number,
            default: 15,
        },
        displayCounter: {
            type: Boolean,
            default: true,
        },
        numberOfItemsInView: {
            type: Number,
            default: 0,
        },
        contentModuleId: {
            type: String,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            products: [],
        };
    },
    computed: {
        slideCounter() {
            return `${this.numberOfItemsInView} / ${this.totalProducts}`;
        },
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        handleViewAllClick() {
            let link = this.link.linkPath;

            if (isObject(link)) {
                link = this.$router.resolve(link)?.path;
            }

            if (link) {
                this.trackGlobalEvent({
                    type: VIEW_ALL_CLICK,
                    data: {
                        productTrayId: this.id,
                        destination: link,
                        contentTitle: this.contentTitle,
                        contentModuleId: this.contentModuleId,
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss">
    .c-product-tray-header {
        &__header-container {
            align-items: end;
            margin-bottom: $nu-spacer-2;
            padding: 0 $nu-spacer-2;

            @include breakpoint(large) {
                padding: 0 $nu-spacer-12;
                margin: 0 $nu-spacer-1 $nu-spacer-4;
            }
        }

        &__header {
            text-align: center;
            vertical-align: middle;
            padding-bottom: 0;

            &--mobile {
                padding: 0 $nu-spacer-2;

                @include breakpoint(large) {
                    display: none;
                }
            }

            &--desktop {
                display: none;

                @include breakpoint(large) {
                    display: block;
                }
            }
        }

        &__browse-link {
            padding-right: $nu-spacer-0pt5;

            @include breakpoint(medium) {
                padding-right: $nu-spacer-1;
            }

            @include breakpoint(large) {
                grid-column-start: inherit;
                padding-right: 0;
            }
        }

        &__counter {
            padding-left: $nu-spacer-0pt5;
            white-space: nowrap;
            line-height: 22px;

            @include breakpoint(medium) {
                padding-left: $nu-spacer-1;
            }

            @include breakpoint(large) {
                padding-left: 0;
            }
        }
    }
</style>
